import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    console.log(this.element.dataset);
    this.pollForStatus(); // Start polling when the controller connects
  }

  disconnect() {
    clearTimeout(this.timeout); // Clean up when the controller disconnects
  }

  pollForStatus() {
    fetch(`/payment/status/${this.element.dataset.sumupstatusPaymentId}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === "PENDING") {
          this.timeout = setTimeout(() => this.pollForStatus(), 3000); // Poll every 3 seconds
        } else {
          window.location.href = this.element.dataset.sumupstatusReturnUrl;
        }
      })
      .catch(error => {
        console.error("Error fetching payment status:", error);
      });
  }
}